@tailwind base;
@tailwind components;
@tailwind utilities;

@import url("https://fonts.googleapis.com/css2?family=Bebas+Neue&family=Luckiest+Guy&family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap");

html {
  scroll-behavior: smooth;
}

::-webkit-scrollbar {
  width: 0px;
}

.no-scroll {
  overflow: hidden;
}

@font-face {
  font-family: Poppins;
  src: url(../public/assets/font/Poppins/Poppins-Regular.ttf);
  font-weight: 400;
  font-style: normal;
}
@font-face {
  font-family: Poppins-extrabold;
  src: url(../public/assets/font/Poppins/Poppins-ExtraBoldItalic.ttf);
  font-weight: 800;
  font-style: italic;
}

@font-face {
  font-family: TitleFont;
  src: url(../public/assets/font/excelsiorcomicsexpandital.ttf);
  font-weight: 100;
  font-style: normal;
}

@font-face {
  font-family: ExcelFont;
  src: url(../public/assets/font/excelsiorcomicsexpand.ttf);
  font-weight: 100;
  font-style: normal;
}

body {
  margin: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

body * {
  font-family: Poppins;
}

.poppins * {
  font-family: Poppins !important;
}

.title > *,
.title {
  font-family: TitleFont;
}

.excelFont {
  font-family: ExcelFont;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

/* Nav */

.navbar-toggle {
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  width: 30px;
  height: 24px;
  background-color: transparent;
  border: none;
  cursor: pointer;
}

.toggle-icon {
  width: 100%;
  height: 4px;
  background-color: #111;
  transition: transform 0.3s ease;
}

.navbar-toggle.open .toggle-icon:nth-child(1) {
  transform: rotate(45deg) translate(6px, 5px);
}

.navbar-toggle.open .toggle-icon:nth-child(2) {
  opacity: 0;
}

.navbar-toggle.open .toggle-icon:nth-child(3) {
  transform: rotate(-45deg) translate(6px, -6px);
}

.sm-shadow {
  filter: drop-shadow(0 16px 10px hsl(0deg 0% 0% / 0.2));
  -webkit-filter: drop-shadow(0 16px 10px hsl(0deg 0% 0% / 0.2));
}

.xs-shadow {
  filter: drop-shadow(0 6px 4px hsl(0deg 0% 0% / 0.2));
  -webkit-filter: drop-shadow(0 6px 4px hsl(0deg 0% 0% / 0.2));
}

.xxs-shadow {
  filter: drop-shadow(-1px 1px 1px rgba(0, 0, 0, 1));
  -webkit-filter: drop-shadow(-1px 1px 1px rgba(0, 0, 0, 1));
}

.xxxs-shadow {
  filter: drop-shadow(-1px 1px 1px rgb(84, 84, 84));
  -webkit-filter: drop-shadow(-1px 1px 1px rgb(84, 84, 84));
}

.emojiLogo {
  width: 100%;
  overflow: visible;
  stroke: #fff;
  stroke-width: 2;
  stroke-linejoin: round;
  stroke-linecap: round;
}

.item_fitness {
  width: 90%;
  overflow: visible;
  stroke: #fff;
  stroke-linejoin: round;
  stroke-linecap: round;
}

.fade-right {
  opacity: 0;
  animation: fade-right 1500ms;
  animation-fill-mode: forwards;
  transition-timing-function: cubic-bezier(0, 0, 0, 1);
}

@keyframes fade-right {
  0% {
    transform: translateX(-50px);
  }
  100% {
    opacity: 1;
    transform: translateX(0px);
  }
}

.fade-in {
  animation: fade-in 0.5s ease-in;
  opacity: 0;
  will-change: opacity;
  animation-fill-mode: forwards;
}

@keyframes fade-in {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

.fade-up {
  opacity: 0;
  animation: fade-up 1500ms;
  animation-fill-mode: forwards;
  transition-timing-function: cubic-bezier(0, 0, 0, 1);
}

@keyframes fade-up {
  0% {
    transform: translateY(50px);
  }
  100% {
    opacity: 1;
    transform: translateY(0px);
  }
}

/* Logo Animation */

.logoAnimation {
  width: 100%;
  overflow: visible;
  stroke: #fff;
  stroke-width: 2;
  stroke-linejoin: round;
  stroke-linecap: round;
}

.logoAnimation2 {
  width: 100%;
  overflow: visible;
  stroke: black;
  stroke-width: 2;
  stroke-linejoin: round;
  stroke-linecap: round;
}

/* SLIDER */

main {
  width: min(1200px, 90vw);
}
.slider {
  width: 100%;
  height: calc(var(--height) * 1.5);
  mask-image: linear-gradient(to right, transparent, #000 10% 90%, transparent);
}
.slider .list {
  display: flex;
  padding-top: 15px;
  width: 100%;
  min-width: calc(var(--width) * var(--quantity));
  position: relative;
}
.slider .list .item {
  width: var(--width);
  height: var(--height);
  position: absolute;
  left: 100%;
  animation: autoRun 20s linear infinite;
  transition: 0.5s;
  animation-delay: calc(
    (20s / var(--quantity)) * (var(--position) - 1) - 20s
  ) !important;
}
.slider .list .item img {
  width: 100%;
  height: 100%;

  filter: drop-shadow(-4px 4px 5px rgba(0, 0, 0, 1));
  -webkit-filter: drop-shadow(-4px 4px 5px rgba(0, 0, 0, 1));
}
@keyframes autoRun {
  from {
    left: 100%;
  }
  to {
    left: calc(var(--width) * -1);
  }
}
.slider:hover .item {
  animation-play-state: paused !important;
  filter: grayscale(1);
}
.slider .item:hover {
  filter: grayscale(0);
  scale: 1.1;
  z-index: 10;
}
.slider[reverse="true"] .item {
  animation: reversePlay 20s linear infinite;
}
@keyframes reversePlay {
  from {
    left: calc(var(--width) * -1);
  }
  to {
    left: 100%;
  }
}

/* Torn Paper */

body {
  /* Settings */
  --torn-background-color: #ffffff;
  --torn-left-width: 21px;
  --torn-right-width: 21px;
  --torn-left-clip-path: polygon(
    59% 0%,
    66% 11%,
    97% 22%,
    55% 33%,
    24% 44%,
    78% 56%,
    37% 67%,
    94% 78%,
    77% 89%,
    25% 100%,
    calc(100% + 1px) 100%,
    calc(100% + 1px) 0%
  );
  --torn-right-clip-path: polygon(
    77% 0%,
    54% 11%,
    50% 22%,
    10% 33%,
    53% 44%,
    69% 56%,
    67% 67%,
    53% 78%,
    51% 89%,
    41% 100%,
    -10% 100%,
    -10% 0%
  );
}

.torn_container {
  position: relative;
  width: fit-content;
}
.torn_container > div:first-child {
  position: absolute;
  width: fit-content;
  z-index: 1;
  filter: blur(var(--torn-shadow-blur));
}

.torn_container > div:nth-child(2),
.torn_left > div:nth-child(2):before,
.torn_right > div:nth-child(2):after {
  background-color: var(--torn-background-color);
}

.torn_left > div:nth-child(2):before,
.torn_left > div:first-child:before {
  content: "";
  position: absolute;
  left: calc(0em - var(--torn-left-width));
  top: 0px;
  width: var(--torn-left-width);
  bottom: 0px;
  -webkit-clip-path: var(--torn-left-clip-path);
  clip-path: var(--torn-left-clip-path);
}
.torn_container > div:first-child,
.torn_left > div:first-child:before,
.torn_right > div:first-child:after {
  background-color: var(--torn-shadow-background-color);
}
.torn_right > div:nth-child(2):after,
.torn_right > div:first-child:after {
  content: "";
  position: absolute;
  left: 100%;
  top: 0px;
  right: calc(0px - var(--torn-right-width));
  bottom: 0px;
  -webkit-clip-path: var(--torn-right-clip-path);
  clip-path: var(--torn-right-clip-path);
}
.torn_right > div:nth-child(2):after {
  left: calc(100% - 0.5px);
}

/* Cours */
.cours:hover p {
  transform: scale(1.1) rotate(-12deg);
  transition: transform 0.5s cubic-bezier(0.14, 1.47, 1, 1.21);
}

.bouncy-transition {
  transition: transform 0.5s cubic-bezier(0.14, 1.47, 1, 1.21);
}

.bouncy-transition * {
  will-change: tranform;
}

.cours::selection {
  background: black;
}

.fc-scrollgrid {
  min-width: 1200px !important;
}
.fc-view {
  overflow-x: scroll !important;
  scrollbar-width: thin;
}

/*FullCalendar Events*/

.fc-h-event {
  /* allowed to be top-level */
  display: block;
  background-repeat: no-repeat;
  background-color: blanchedalmond;
  white-space: normal;
}

.fc-daygrid-day-frame {
  background: rgba(255, 255, 255, 0.47);
}

.register-button img {
  transform: translate(0px, 0px);
  transition: 0.4s cubic-bezier(0.075, 0.82, 0.165, 1);
}

.register-button img:nth-child(3) {
  transform: translate(-50%, 0px);
  transition: 0.4s cubic-bezier(0.075, 0.82, 0.165, 1);
}

.register-button:hover img:first-child {
  transform: translate(25px, -32px) rotate(12deg);
}

.register-button:hover img:nth-child(2) {
  transform: translate(-25px, -32px) rotate(-12deg);
}
.register-button:hover img:nth-child(3) {
  transform: translate(-50%, -55px);
}

.customShadow {
  position: relative;
  -webkit-text-stroke: 10px black; /* width and color */
}
.customShadow::after {
  transform: translate(-0.1em, 0.1em); /* Adjust the shadow offset */
  content: attr(data-content);
  filter: blur(3px);
  position: absolute;
  bottom: 0;
  left: 0px; /* Adjust the horizontal position of the shadow */
  color: rgba(0, 0, 0, 0.22); /* Shadow color and opacity */
  z-index: -1; /* Place the shadow behind the text */
  -webkit-text-stroke: 8px rgba(0, 0, 0, 0.2); /* width and color */
}

/* Masonry */

.my-masonry-grid {
  display: -webkit-box; /* Not needed if autoprefixing */
  display: -ms-flexbox; /* Not needed if autoprefixing */
  display: flex;
  margin-left: -30px; /* gutter size offset */
  width: auto;
}
.my-masonry-grid_column {
  padding-left: 30px; /* gutter size */
  background-clip: padding-box;
}

/* Style your items */
.my-masonry-grid_column > div { /* change div to reference your elements you put in <Masonry> */
  
  margin-bottom: 10px;
}