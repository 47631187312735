.window {
  max-width: 95vw;
  background: white;
  overflow: hidden;

  display: flex;
  flex-direction: column;
}

.window nav {
  background: #fdfdfd;
  padding: 5px 5px 0;
  border-radius: 10px;
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0;
  border-bottom: 1px solid #eeeeee;
  height: fit-content;
}

.window .tabs {
  flex-grow: 1;
  display: flex;
  justify-content: flex-start;
  align-items: flex-end;
  flex-wrap: nowrap;
  width: 100%;
}

.window main {
  display: flex;
  width: 100%;
  height: 100%;
  justify-content: center;
  align-items: center;
  user-select: none;
}

.window ul,
.window li {
  padding: 0;
  margin: 0;
  font-family: "Poppins", sans-serif;
  font-weight: 500;
  font-size: 14px;
}

.window li {
  border-radius: 5px;
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0;
  width: 100%;
  padding: 30px 25px;
  position: relative;
  background: white;
  cursor: pointer;
  height: 38px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex: 1;
  min-width: 0;
  position: relative;
  user-select: none;
}

.window .underline {
  position: absolute;
  bottom: 0px;
  left: 0;
  right: 0;
  height: 2px;
  background: black;
}

.window li.selected {
  background: #eee;
}

.window li button {
  width: 20px;
  height: 20px;
  border: 0;
  background: #fff;
  border-radius: 3px;
  display: flex;
  justify-content: center;
  align-items: center;
  stroke: #000;
  margin-left: 10px;
  cursor: pointer;
  flex-shrink: 0;
}

.window .background {
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  width: 300px;
  background: #fff;
}

.window .add-item {
  width: 30px;
  height: 30px;
  background: #eee;
  border-radius: 50%;
  border: 0;
  cursor: pointer;
  align-self: center;
}

.window .add-item:disabled {
  opacity: 0.4;
  cursor: default;
  pointer-events: none;
}
